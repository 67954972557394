.event-details-details {

    width: 100%;

    .toolbar {
        display: flex;
        margin-bottom: 31px;
        margin-top: 28px;
        @media (max-width: 576px) {
            flex-wrap: wrap;
        }

        button {
            margin-left: 15px;

            &:first-child {
                margin-left: 0;
                margin-right: auto;
            }

            @media (max-width: 576px) {
                margin-left: 11px;
            }
        }

        .back-button {
            @media (max-width: 576px) {
                margin-left: 0;
                min-width: unset;
                width: calc(50% - 5.5px);
            }
        }

        .summary-button {

            min-width: 315px;

        }

        .preview-button {
            @media (max-width: 576px) {
                min-width: unset;
                width: calc(50% - 5.5px);
            }
        }

        .save-button {
            @media (max-width: 576px) {
                margin-left: 0;
                margin-top: 11px;
                min-width: unset;
                width: calc(50% - 5.5px);
            }
        }
    }

    .horizontal {
        display: flex;
        @media (max-width: 576px) {
            flex-direction: column;
        }

        .card-div {
            margin-bottom: 20px;
            margin-right: 20px;
            @media (max-width: 576px) {
                flex-direction: column;
                margin-right: 0;
                width: calc(100% - 42px) !important;
            }

            &--max-width {

                margin-right: 0;
                width: 100%;

                h2 {

                    margin-bottom: 20px;

                }

            }
        }
    }

    .card-div {
        width: min-content;

        .column {
            margin-right: 30px;
            @media (max-width: 576px) {
                margin-right: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }

        &--disabled {
            border-color: rgba(0, 0, 0, 0.2);
            pointer-events: none;
            position: relative;
            overflow: hidden;

            &::before {
                background-color: rgba(0, 0, 0, 0.2);
                content: "";
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;
            }
        }
    }

    .form {
        display: none;

        &--open {
            display: block;
        }
    }

    .stat-card-div {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        padding: 20px;
        width: calc(100% - 40px);

        header {
            align-items: flex-start;
            display: flex;
            justify-content: space-between;
            margin-bottom: 18px;
            @media (max-width: 576px) {
                flex-direction: column;
            }

            h2 {
                color: var(--color-primary);
                font-size: 22px;
                font-weight: 700;
                line-height: 26px;
                margin: 0;
            }

            a {
                color: var(--color-primary);
                font-size: 12px;
                font-weight: 700;
                line-height: 14px;
                margin-top: 3px;
                text-decoration: none;
            }
        }

        .caption {
            color: #363638;
            font-size: 14px;
            font-weight: 700;
            line-height: 16px;
            margin-bottom: 15px;
        }

        .info-slots {
            display: flex;
            justify-content: space-between;
            @media (max-width: 576px) {
                flex-direction: column;
            }

            .info-slot {
                width: 100%;
                @media (max-width: 576px) {
                    margin-bottom: 15px;
                    width: calc(100% - 40px);
                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                .caption {
                    margin-bottom: 4px;
                }

                &:first-child {
                    margin-right: 16px;
                }
            }
        }
    }

    .notifications-card-div {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        padding: 20px;
        width: calc(100% - 40px);

        header {
            align-items: flex-start;
            display: flex;
            justify-content: space-between;
            margin-bottom: 49px;
            @media (max-width: 576px) {
                flex-direction: column;
            }

            h2 {
                color: var(--color-primary);
                font-size: 22px;
                font-weight: 700;
                line-height: 26px;
                margin: 5px 0 0 0;
            }

            button {
                min-width: 249px;
                @media (max-width: 576px) {
                    margin-top: 15px;
                    width: 100%;
                }
            }
        }

        .notifications {
            display: flex;
            justify-content: flex-start;
            @media (max-width: 576px) {
                flex-direction: column;
            }

            .notification {
                box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
                border-radius: 8px;
                display: flex;
                flex-direction: column;
                margin-right: 68px;
                padding: 10px;
                width: calc((100% - 20px - 136px - 40px) / 3);
                @media (max-width: 576px) {
                    margin-bottom: 15px;
                    width: calc(100% - 20px);
                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                footer {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 8px;
                }

                &__text {
                    color: #363638;
                    font-size: 16px;
                    height: 76px;
                    line-height: 19px;
                    overflow: hidden;
                }

                &__date {
                    color: #59595b;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 16px;
                }

                &__delete {
                    align-items: center;
                    color: #c30870;
                    cursor: pointer;
                    display: flex;
                    font-size: 12px;
                    font-weight: 700;
                    line-height: 14px;

                    span.material-symbols-outlined {
                        font-size: 14px;
                        margin-right: 5px;
                    }
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    .galleries-card-div {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        padding: 20px;
        width: calc(100% - 40px);

        header {
            align-items: flex-start;
            display: flex;
            justify-content: space-between;
            margin-bottom: 18px;

            h2 {
                color: var(--color-primary);
                font-size: 22px;
                font-weight: 700;
                line-height: 26px;
                margin: 5px 0 0 0;
            }
        }

        .caption {
            color: #0267aa;
            font-size: 18px;
            font-weight: 700;
            line-height: 21px;

            &--review-needed {
                color: #c30870;
            }
        }

        .galleries {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            padding-top: 10px;
            overflow: hidden;
            width: 100%;
            @media (max-width: 576px) {
                flex-direction: column;
            }

            span {
                margin-bottom: 18px;
            }
        }

        .gallery {
            border-radius: 8px;
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);
            margin-bottom: 18px;
            margin-right: 10px;
            padding: 10px;
            width: calc(25% - (30px / 4) - 20px - 4px);
            @media (max-width: 576px) {
                margin-right: 0;
                width: calc(100% - 20px - 4px);
            }

            .images {
                display: flex;
                height: 237.16px;
                flex-wrap: wrap;
            }

            .image {
                display: inline-flex;
                position: relative;

                &.selected {
                    img {
                        border: 2px solid #c30870;
                    }

                    .image__delete {
                        display: flex;
                    }
                }

                &__delete {
                    align-items: center;
                    background-color: #c30870;
                    border-top-left-radius: 10px;
                    bottom: 0;
                    color: white;
                    cursor: pointer;
                    display: none;
                    height: 30px;
                    position: absolute;
                    right: 0;
                    justify-content: center;
                    width: 58px;
                }

                img {
                    box-sizing: border-box;
                    height: 100%;
                    object-fit: cover;
                    object-position: center center;
                    width: 100%;
                }

                &:nth-child(1) {
                    aspect-ratio: 274 / 150;
                    margin-bottom: 8px;
                    width: 100%;
                }

                &:nth-child(2) {
                    aspect-ratio: 133 / 73;
                    margin-right: 8px;
                    width: calc(50% - 4px);
                }

                &:nth-child(3) {
                    aspect-ratio: 133 / 73;
                    width: calc(50% - 4px);
                }
            }

            footer {
                align-items: center;
                display: flex;
                justify-content: space-between;
                margin-top: 10px;

                span {
                    cursor: pointer;
                    margin: 0 5px;

                    &:nth-child(2) {
                        color: #c30870;
                    }
                }
            }

            &__date {
                color: #363638;
                font-size: 12px;
                line-height: 14px;
                margin-bottom: 3px;
            }

            &__owner {
                color: #363638;
                font-size: 14px;
                font-weight: 700;
                line-height: 16px;
            }

            &--review-needed {
                border: 2px solid #c30870;
                box-shadow: 0 0 10px 0 rgba(195, 8, 112, 0.08);

                footer {
                    text-align: center;

                    span {
                        &:nth-child(1) {
                            color: #27b06c;
                        }

                        &:nth-child(3) {
                            color: #c30870;
                        }
                    }
                }
            }

            &:nth-child(4),
            &:nth-child(8) {
                margin-right: 0;
            }

            &:nth-child(n + 5) {
                display: none;
            }
        }

        footer {
            .button {
                min-width: 288px;
                @media (max-width: 576px) {
                    margin-left: 0;
                    width: 100%;
                }
            }
        }

        .pagination {
            align-items: center;
            background-color: #f4f4f4;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
            color: var(--color-dark);
            display: none;
            font-size: 16px;
            font-weight: 700;
            justify-content: flex-end;
            line-height: 19px;
            margin-left: -21px;
            margin-right: -21px;
            margin-bottom: -21px;
            padding: 8px;

            button {
                align-items: center;
                background-color: white;
                border: 0;
                border-radius: 50%;
                box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
                display: flex;
                height: 26px;
                justify-content: center;
                margin: 0 25px;
                min-width: 26px;
                width: 26px;

                &[disabled] {
                    pointer-events: none;

                    img {
                        opacity: 0.5;
                    }
                }

                img {
                    height: 8px;
                }
            }
        }

        &--open {
            .pagination {
                display: flex;
            }

            .gallery {
                &:nth-child(n + 5) {
                    display: block;
                }
            }

            > footer {
                display: none;
            }
        }
    }


    .summary-card-div,
    .details-card-div {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        padding: 20px 25px 20px 20px;
        width: calc(100% - 45px);

        header {
            align-items: flex-start;
            display: flex;
            justify-content: space-between;

            h2 {
                color: var(--color-primary);
                font-size: 22px;
                font-weight: 700;
                line-height: 26px;
                margin: 5px 0 0 0;
            }
        }

        main {

            height: 0;
            margin-top: 0;
            overflow: hidden;

        }

        &--open {

            main {

                margin-top: 30px;
                height: unset;
                padding-bottom: 5px;


            }

        }

        .summary-empty {

            color: #808080;
            font-style: italic;
        }

        button {
            align-items: center;
            background-color: white;
            border: 0;
            border-radius: 50%;
            box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
            cursor: pointer;
            display: flex;
            height: 26px;
            justify-content: center;
            min-width: 26px;
            width: 26px;

            img {
                height: 8px;
                transition: rotate 0.3s;

                &.rotated {
                    rotate: 90deg;
                }
            }
        }
    }

    .select-background {
        height: calc(48px - 14px - 14px);
        border-radius: 8px;
        text-align: left !important;
        border: 1px solid var(--color-border);
        display: flex;
        flex-direction: row;
        justify-content: left;
        padding: 14px 16px;
        margin-top: 15px;
        min-width: 200px;

        .select-text {
            padding-top: 8px;
            margin-left: 16px;
            margin-right: 8px;
            width: 40%;
        }

        .select-arrow {
            margin-bottom: 18px !important;
            width: 5px !important;
            height: 10px !important;
        }
    }

    .input-group-div {
        align-items: center;
        display: flex;
        min-width: 400px;
        @media (max-width: 576px) {
            min-width: unset;
        }

        input {
            margin-top: 0;
            position: static;
        }
    }

    .politician-selector {
        display: flex;
        margin-top: 15px;

        .politician-image {
            border-radius: 8px;
            margin-right: 15px;
            overflow: hidden;

            img {
                height: 100px;
                object-fit: cover;
                object-position: center center;
                width: 80px;
            }
        }

        .politician-name {
            font-size: 16px;
            font-weight: 700;
            line-height: 19px;
            margin-bottom: 45px;
        }
    }

    .image-section-container {
        width: 290px;
        margin-right: 18px;

        .image-container {
            width: calc(255px - 14px - 14px);
            height: calc(120px - 14px - 14px);
            border-radius: 5px;
            text-align: center;
            border: 1px solid var(--color-primary);
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 14px 22px;
            margin-top: 20px;

            .image-container-image {
                width: 48px;
                height: 48px;
                border-radius: 50%;
                background: var(--color-primary);
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-bottom: 10px;
            }
        }

        .image {
            width: 255px;
            //   height: 120px ;
            // max-height:  120px;
            height: auto;
            margin-top: 20px;
            border-radius: 5px;
            border: 1px solid var(--color-primary);
        }
    }

    .columns {
        display: flex;
        margin-bottom: 38px;
        @media (max-width: 576px) {
            flex-direction: column;
            .column {
                width: 100% !important;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    .input-container-header {
        display: flex;
        flex-direction: column;

        .label-text {
            align-self: flex-end;
            font-size: 12px;
            line-height: 14px;
            margin-bottom: 5px;
        }
    }

    .description-edititor-container {
        height: 24px;
        border-radius: 3px;
        text-align: center;
        border: 1px solid var(--color-primary);
        padding: 1px;
        width: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: calc(21px + 18px);
        left: 19px;

        .separator {
            background-color: var(--color-primary);
            height: 24px;
            width: 1px;
            margin: 0;
            padding: 0;
        }

        .editor-button {
            height: 24px;
            width: 24px;
            background-color: white;
            border: 1px solid transparent;
            cursor: pointer;
            transition: background-color var(--transition-speed);

            &:hover {
                background-color: var(--color-highlight);
            }
        }

        .bold {
            color: var(--color-primary);
            font-family: var(--font-primary);
            font-size: 14px;
            font-weight: 700;
            line-height: 16px;
        }

        .italic {
            color: var(--color-primary);
            font-family: var(--font-primary);
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
            font-style: italic;
        }

        .underline {
            color: var(--color-primary);
            font-family: var(--font-primary);
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
            text-decoration: underline;
        }

        .link {
            color: var(--color-primary);
            font-size: 14px;
            font-weight: 200;
            line-height: 18px;
            margin: 0;
            padding: 0;
        }
    }

    .field.area--text {
        margin-top: 5px;

        textarea {
            padding: 47px 19px 13px 19px !important;
            width: calc(100% - 19px - 19px - 2px) !important;
        }
    }

    .shared-text-button-field {
        align-items: center;
        display: flex;

        > div {
            width: 100%;
        }

        button {
            margin-left: 22px;
        }
    }

    #leaflet-map {
        border-radius: 6px;
        height: 300px;
        margin-top: 16px;
        width: 100%;
    }

    footer {
        display: flex;
        justify-content: flex-end;
        width: 100%;

        button {
            margin-left: 15px;
            @media (max-width: 576px) {
                margin-left: 11px;
            }
        }

        .preview-button {
            @media (max-width: 576px) {
                margin-left: 0;
                min-width: unset;
                width: calc(50% - 5.5px);
            }
        }

        .save-button {
            @media (max-width: 576px) {
                min-width: unset;
                width: calc(50% - 5.5px);
            }
        }
    }

    .react-datepicker-wrapper {
        margin-top: 15px;

        .react-datepicker__input-container {
            height: 48px;

            input {
                cursor: pointer;
                border-radius: 8px;
                border: 1px solid var(--color-border);
                height: calc(48px - 14px - 14px) !important;
                min-width: 200px;
                padding: 14px 16px;
                text-align: center;
                font-size: 14px;
                font-weight: 400;
                line-height: 16px;
                color: var(--color-font);
                font-family: var(--font-primary);

                &.error {
                    border-color: var(--color-warning);
                }
            }
        }
    }

    .confirm-delete-message-modal {

        .modal-title {

            color: var( --color-primary );
            font-size: 22px;
            font-weight: 900;
            line-height: 24px;
            margin-bottom: 30px;
            text-align: center;

        }

        .modal-description {

            text-align: center;
            margin-bottom: 30px;

        }

    }

    .politicians-list {

        align-items: flex-start;
        display: flex;

    }

    .add-politician {

        border: 1px solid var( --color-primary );
        border-radius: 8px;
        box-shadow: 0 0 10px 0 rgba( 0, 0, 0, 0.08 );
        cursor: pointer;
        display: flex;
        flex-direction: column;
        margin-right: 20px;
        padding: 8px 0 3px 0;
        width: 78px;

        img {

            height: 48px;
            margin: 0 16px 11px 16px;
            width: 48px;

        }

        span {

            color: var( --color-primary );
            font-size: 12px;
            font-weight: 700;
            line-height: 13px;
            margin-bottom: 5px;
            text-align: center;

        }

    }

    .host-politician {

        display: flex;
        flex-direction: column;
        margin-right: 20px;
        position: relative;
        width: 110px;

        > img {

            border-radius: 8px;
            height: 100px;
            margin-left: 15px;
            margin-bottom: 10px;
            object-fit: cover;
            object-position: center center;
            width: 80px;

        }

        span {

            color: #363638;
            height: 17px;
            font-size: 16px;
            font-weight: 700;
            line-height: 17px;
            overflow: hidden;
            text-align: center;

        }

        .button--menu--container {

            height: 0;

        }

        .button--menu {

            position: absolute;
            right: 10px;
            bottom: 30px;
            margin: 0 !important;

            span {

                height: 16px !important;

            }

        }

        .button--menu--items div img.icon {

            height: 14px;
            margin-right: 0 !important;
            object-fit: contain;
            object-position: center center;
            width: 14px;

        }

    }

    .card-div--achievement {

        align-items: center;
        display: flex;
        flex-direction: column;
        min-width: 240px;

        .caption {

            color: var( --color-primary );
            font-size: 22px;
            font-weight: 700;
            line-height: 26px;
            margin-bottom: 10px;

        }

    }

    .visibility-h2 {

        img {
            cursor: pointer;
            top: 2px;
            margin-left: 15px;
            position: relative;
            width: 18px;
        }

    }

}

.eventimage-confirm-modal {

    text-align: center;

    .button {

        margin: 0 10px;

        &--warning {

            background-color: rgba( 195, 8, 112, 0.2 );
            border-color: #C30870;
            color: #C30870;

        }

    }

    .modal-title {

        color: var( --color-primary );
        font-size: 26px;
        font-weight: 900;
        line-height: 29px;
        margin-bottom: 40px;

    }

    .modal-description {

        color: #59595B;
        font-size: 16px;
        line-height: 17px;
        margin-bottom: 28px;
        padding: 0 100px;

    }

}