.page--homepage {

    align-items: center;
    width: 100%;
    padding: 30px;
    display: flex;
    flex-direction: column;

    @media (max-width: 576px) {

        padding: 15px;

    }

    .main-div {

        display: flex;
        flex-direction: column;
        justify-content: left;
        position: relative;
        width: 1252px;

        @media (max-width: 576px) {

            width: 100%;

        }

        .card-div {

            display: flex;
            flex-direction: column;
            margin-top: 30px;
            margin-bottom: 60px;

            h2 {
                margin-bottom: 30px;
            }

            h3 {
                margin-bottom: 5px;
            }

            &--no-border {

                border: none;
                padding: 0;

            }

        }

        .input-field {

            display: flex;
            flex-direction: column;
            margin-bottom: 15px;

        }

        .input-fields {

            display: flex;
            gap: 30px;

        }

        footer {

            display: flex;
            justify-content: flex-end;
            margin-top: 20px;

        }

        .react-datepicker-wrapper {

            .react-datepicker__input-container {

                height: 48px;

                input {
                    cursor: pointer;
                    border-radius: 8px;
                    border: 1px solid var(--color-border);
                    height:calc(48px - 14px - 14px) !important;
                    min-width: 180px;
                    padding: 14px 16px ;
                    text-align: left;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 16px;
                    color: var(--color-font);
                    font-family: var(--font-primary);
                    width: calc( 100% - 32px );
                }

            }
        }

    }

}
