.page-questionnaire-list {

    .toolbar {

        margin-bottom: 30 !important;

        @media (max-width: 576px) {

            height: unset !important;
            flex-wrap: wrap;

            .field--search {

                width: calc( 100% - 22px - 16px - 16px - 2px - 11px );

            }

            .archive-button {

                margin-left: 0;
                margin-top: 11px;
                min-width: unset;
                width: calc( 50% - 5.5px );

            }

            .new-a {

                margin-left: 11px;
                margin-top: 11px;
                width: calc( 50% - 5.5px );

            }

            .new-button {

                margin-left: 0;
                min-width: unset;
                width: 100%;

            }

        }

    }

    .button {

        margin-left: 15px;
        height: 100%;

        @media (max-width: 576px) {

            margin-left: 11px;
            height: 38px;

        }

    }

    .info {
        margin-top: 30px;
        margin-bottom: 30px;

        width: calc(100% - 20px - 20px);
        padding: 20px 20px;
        display:flex;
        flex-direction: column;
        justify-content: left;
        background-color: white;
        border: 1px solid transparent;
        border-radius: 8px;
        box-shadow: 0 0px 14px 0 rgba(0, 0, 0, 0.08);
    }
    
    .bottom_bar {
        margin-top: 28px;
        height: 36px;
        width: 100%;
        display:flex;
        flex-direction: row;
        justify-content: right;

        .label-text {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .info {
        margin-top: 30px;
        margin-bottom: 30px;

        width: calc(100% - 20px - 20px);
        padding: 20px 20px;
        display: flex;
        flex-direction: column;
        justify-content: left;
        background-color: white;
        border: 1px solid transparent;
        border-radius: 8px;
        box-shadow: 0 0px 14px 0 rgba(0, 0, 0, 0.08);

        @media (max-width: 576px) {
            width: calc(100% - 20px - 20px - 2px);
        }

        .inner {
            border: 1px solid var(--color-light-gray);
            width: calc(100% - 20px - 20px);
            padding: 13px 20px;
            display: flex;
            align-items: center;
            flex-direction: row;
            align-content: flex-start;

            .title {
                width: 25%;
                text-align: left;
                white-space: pre-line;
                overflow: hidden;
                text-overflow: ellipsis;
                max-height: 32px;
                align-items: center;
            }

            .tag-container {
                width: 15%;
                text-align: center;
            }

            .date {
                width: 25%;
                text-align: center;
            }

            .open-count {
                width: 25%;
                text-align: center;
            }
            @media (max-width: 576px) {
                .title{
                    width: 50%;
                }
                .open-count {
                    font-size: 12px;
                }
                .date {
                    visibility: hidden;
                    width: 0%;
                    // font-size: 12px;
                }
                .tag-container {
                    visibility: hidden;
                    width: 0%;
                }
            }

            .count-container {
                width: 20%;
                text-align: center;
                margin-bottom: 2px;
                .count {
                    font-size: 24px;
                    line-height: 18px;
                    text-align: center;
                }
                @media (max-width: 576px) {
                    .count {
                        font-size: 16px;
                    }
                }

                .person {
                    margin-left: 4px;
                    text-align: center;
                }
            }

            .menu-container {
                width: 3%;
                text-align: center;
            }
        }
    }
}

.questionnaire-table-confirm-modal {

    text-align: center;

    .button {

        margin: 0 10px;

        &--warning {

            background-color: rgba( 195, 8, 112, 0.2 );
            border-color: #C30870;
            color: #C30870;

        }

    }

    .modal-title {

        color: var( --color-primary );
        font-size: 26px;
        font-weight: 900;
        line-height: 29px;
        margin-bottom: 40px;

    }

    .modal-description {

        color: #59595B;
        font-size: 16px;
        line-height: 17px;
        margin-bottom: 28px;
        padding: 0 100px;

    }

}