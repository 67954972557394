.banned-words-modal {
  height: calc(536px - 30px - 72px);
  max-height: 80vh;
  max-width: 80vw;
  padding: 30px 20px 72px 11.5px;
  position: relative;
  width: calc(1156px - 11.5px - 20px);

  .modal-title {
    color: var(--color-primary);
    font-size: 26px;
    font-weight: 900;
    line-height: 29px;
    margin-bottom: 30px;
    text-align: center;
  }

  .modal-close {
    cursor: pointer;
    height: 14px;
    position: absolute;
    right: 21.35px;
    top: 25.5px;
    width: 14px;
  }

  main {
    display: flex;
      height: 100%;

    .quote-parties {
      min-width: 400px;
      width: 400px;
      @media (max-width: 576px) {
        min-width: 250px;
        width: 250px;
      }
      .banned-word {
        align-items: center;
        display: flex;
        margin-bottom: 8.5px;

        span {
          margin-right: auto;
        }
      }

      button {
        border-radius: 8px;
        margin: 11.5px 0;
        width: 100%;
      }
    }

    .quote-party-form {
      padding: 30px;

      &__title {
        color: var(--color-primary);
        font-size: 22px;
        font-weight: 700;
        line-height: 24px;
        margin-bottom: 25px;
        text-align: center;
      }

      .input-field {
        display: flex;
        width: 100%;

        label {
          color: var(--color-primary);
          font-size: 18px;
          font-weight: 700;
          line-height: 20px;
          margin: 6px 24px 0 0;
          width: 151px;
        }

        .field {
          width: calc(100% - 151px - 24px);
        }

        input {
          font-size: 16px !important;
          line-height: 19px !important;
          height: 19px !important;
          padding: 8px 16px !important;
        }
      }

        footer {

            .button {

                &--warning {

                    background-color: rgba( 195, 8, 112, 0.2 );
                    border-color: #C30870;
                    color: #C30870;

                }

            }

        }

    }

    .separator {
      background-color: var(--color-border);
      margin: 0 30px;
      width: 1px;
    }
  }

  footer {
    display: flex;
    justify-content: center;
    margin-top: 11px;
  }

  .banned-words {
      height: 100%;

      .list {
          height: calc( 100% - 50px );
          margin-bottom: 20px;
          overflow-y: scroll;
      }

    .caption {
      margin-bottom: 14px;
    }

    .banned-word {
      border: 1px solid #dddddd;
      border-radius: 8px;
      font-size: 16px;
      line-height: 17px;
      margin-bottom: 8.5px;
      padding: 11px 17px;

      &:last-child {
        margin-bottom: 0;
      }

      &--selected {
        border: 2px solid var(--color-primary);
      }
    }
  }

  .input-field {
    margin-bottom: 15px;
    position: relative;

    input {
      color: #363638 !important;
      font-size: 16px !important;
      font-weight: 400 !important;
      height: 17px !important;
      line-height: 17px !important;
      padding: 14px 20px !important;
    }

    .info {
      color: #59595b;
      font-size: 12px;
      line-height: 14px;
      text-align: right;
      position: absolute;
      top: 6px;
      right: 0;
    }

    &--strong {
      input {
        border-width: 2px !important;
        font-weight: 700 !important;
      }
    }
  }

    .confirm-delete-party-modal {

        .modal-title {

            color: var( --color-primary );
            font-size: 22px;
            font-weight: 900;
            line-height: 24px;
            margin-bottom: 30px;
            text-align: center;

        }

        .modal-description {

            text-align: center;
            margin-bottom: 30px;

        }

        footer {

            margin: 0 !important;

        }

    }

}
