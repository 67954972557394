.page--videos-list {

    align-items: center;
    width: 100%;
    padding: 30px;
    display: flex;
    flex-direction: column;

    @media (max-width: 576px) {

        align-items: flex-start;

    }

    .main-div {

        display: flex;
        flex-direction: column;
        justify-content: left;
        position: relative;
        width: 1252px;


        @media (max-width: 576px) {

            width: calc(100% - 0px);

        }

        .toolbar {

            display: flex;
            margin: 30px 0;

            button {

                margin-left: 15px;

            }

        }

        .card-div {

            border: none;
            margin-bottom: 30px;
            padding: 20px;
            width: calc( 100% - 40px );

            &--table {

                padding: 0 !important;
                width: 100%;

            }

            &--highlighted {

                flex-direction: column;

                h2 {

                    margin-bottom: 15px;

                }

                .card-div {

                    align-items: center;
                    display: flex;
                    margin-bottom: 0;

                    .title {

                        color: #363638;
                        font-size: 14px;
                        font-weight: 700;
                        line-height: 16px;
                        margin-right: 50px;
                        width: 400px;

                    }

                    .topic-holder {

                        display: flex;
                        justify-content: center;
                        margin-right: 50px;
                        width: 213px;

                        .course-topic {

                            width: min-content;

                        }

                    }

                    .start-date {

                        color: #363638;
                        font-size: 14px;
                        line-height: 16px;
                        margin-right: 50px;

                    }

                    .fills {

                        align-items: center;
                        color: #363638;
                        display: flex;
                        font-size: 17px;
                        line-height: 20px;
                        margin-right: 50px;

                        span {

                            font-size: 14px;
                            line-height: 16px;
                            margin-right: 4px;

                        }

                    }

                    .button--menu--container {

                        margin-left: auto;
                        width: 30px;

                    }

                }

            }

        }

    }

    .info {
        margin-top: 30px;
        margin-bottom: 30px;

        width: calc(100% - 20px - 20px);
        padding: 20px 20px;
        display: flex;
        flex-direction: column;
        justify-content: left;
        background-color: white;
        border: 1px solid transparent;
        border-radius: 8px;
        box-shadow: 0 0px 14px 0 rgba(0, 0, 0, 0.08);

        @media (max-width: 576px) {
            width: calc(100% - 20px - 20px - 2px);
        }

        .inner {
            border: 1px solid var(--color-light-gray);
            width: calc(100% - 20px - 20px);
            padding: 13px 20px;
            display: flex;
            align-items: center;
            flex-direction: row;
            align-content: flex-start;

            .title {
                width: 25%;
                text-align: left;
                white-space: pre-line;
                overflow: hidden;
                text-overflow: ellipsis;
                max-height: 32px;
                align-items: center;
            }

            .tag-container {
                width: 15%;
                text-align: center;
            }

            .date {
                width: 25%;
                text-align: center;
            }

            .open-count {
                width: 25%;
                text-align: center;
            }
            @media (max-width: 576px) {
                .title{
                    width: 50%;
                }
                .open-count {
                    font-size: 12px;
                }
                .date {
                    visibility: hidden;
                    width: 0%;
                    // font-size: 12px;
                }
                .tag-container {
                    visibility: hidden;
                    width: 0%;
                }
            }

            .count-container {
                width: 20%;
                text-align: center;
                margin-bottom: 2px;
                .count {
                    font-size: 24px;
                    line-height: 18px;
                    text-align: center;
                }
                @media (max-width: 576px) {
                    .count {
                        font-size: 16px;
                    }
                }

                .person {
                    margin-left: 4px;
                    text-align: center;
                }
            }

            .menu-container {
                width: 3%;
                text-align: center;
            }
        }
    }

}