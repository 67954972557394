.page--mobile-app-user {
  h1 {
    a {
      color: inherit;
      text-decoration: none;
    }
  }

  .main-div {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .lineBreak {
    display: inline-block;
  }
  @media (max-width: 576px) {
    .lineBreak {
        word-wrap: break-word;
        white-space: pre-wrap;
        margin-bottom: 5px;
      }
  }
  .mobile-app-user-layout {
    display: grid;
    gap: 30px;
    grid-template-columns: 2fr 1fr;
    @media (max-width: 576px) {
      grid-template-columns: none;
    }

    .card-div:last-child {
      height: min-content;
    }

    .card-div:first-child {
      grid-column: 1 / 3;
      @media (max-width: 576px) {
        grid-column: unset;
      }
      padding: 30px 22px;
      width: calc(100% - 22px - 22px);

      header {
        position: relative;
        padding-bottom: 26.5px;
        margin-bottom: 19.5px;

        &::after {
          background-color: #f5f7fa;
          content: "";
          height: 1px;
          left: 0;
          position: absolute;
          top: calc(100% - 1px);
          width: 100%;
          z-index: 0;
        }
      }
    }
  }

  .card-div {
    border: none;
    flex-direction: column;
    padding: 18px;
    overflow: hidden;
    width: calc(100% - 18px - 18px);

    header {
      align-items: center;
      display: flex;

      .button {
        margin-left: 20px;
      }
    }
  }

  .user-name {
    color: var(--color-primary);
    font-size: 26px;
    font-weight: 900;
    line-height: 30px;
    margin-right: 42px;

      .username {
          font-size: 22px;
          font-weight: 700;

          i {
              font-style: normal;
              color: #C30870;
              text-transform: uppercase;
          }
      }
  }

  .user-experience {
    align-items: center;
    color: var(--color-gray);
    display: flex;
    font-size: 16px;
    line-height: 17px;
    margin-right: auto;

    img {
      height: 26px;
      margin-right: 4px;
    }
  }

  .user-main-info {
    display: flex;
  }

  .main-info {
    margin-right: 105px;
    @media (max-width: 576px) {
        margin-right: 25px;
    }

    &__caption {
      color: var(--color-primary);
      font-size: 22px;
      font-weight: 700;
      line-height: 24px;
      margin-bottom: 8px;
    }
  }

  .tab-view {
    header {
      display: flex;
        margin-bottom: 8px;

      .tab {
        border-bottom: 2px solid transparent;
        color: var(--color-primary);
        font-size: 18px;
        font-weight: 700;
        line-height: 20px;
        margin-right: 32px;
        padding-bottom: 6px;

        &--active {
          border-color: var(--color-primary);
        }
      }
    }
  }

  .user-details {
    display: flex;
    flex-direction: column;

    .user-detail {
      align-items: center;
      display: flex;
      margin-bottom: 39px;

      &__caption {
        color: var(--color-primary);
        font-size: 18px;
        font-weight: 700;
        line-height: 20px;
        margin-right: 39px;
        width: 152px;
      }

      &__value {
        font-size: 16px;
        line-height: 17px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .user-tasks-table {
    margin-top: -32px;

    label.checkbox {
      margin: 0;
    }
  }

  .user-activities {
    &__empty {
      font-size: 14px;
      padding: 20px;
      text-align: center;
    }
  }

  .user-tags {
    header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 23px;
    }

    .caption {
      color: var(--color-primary);
      font-size: 22px;
      font-weight: 700;
      line-height: 24px;
    }

    &__tags {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
    }

    &__empty {
      font-size: 14px;
      padding: 20px;
      text-align: center;
    }

    .user-tag {
      align-items: center;
      border: 1px solid var(--color-gray);
      border-radius: 10px;
      color: var(--color-gray);
      display: flex;
      font-size: 12px;
      line-height: 13px;
      padding: 3px 12px;

      .material-symbols-outlined {
        cursor: pointer;
        font-size: 13px;
        margin-left: 5px;
      }
    }
  }

    .user-confirm-modal {

        text-align: center;

        .button {

            margin: 0 10px;

            &--warning {

                background-color: rgba( 195, 8, 112, 0.2 );
                border-color: #C30870;
                color: #C30870;

            }

        }

        .modal-title {

            color: var( --color-primary );
            font-size: 26px;
            font-weight: 900;
            line-height: 29px;
            margin-bottom: 40px;

        }

        .modal-description {

            color: #59595B;
            font-size: 16px;
            line-height: 17px;
            margin-bottom: 28px;
            padding: 0 100px;

        }

    }

    .tabs {

        display: flex;
        flex-wrap: wrap;
        margin-bottom: 30px;

        a {

            border-bottom: 2px solid transparent;
            cursor: pointer;
            font-size: 18px;
            line-height: 20px;
            margin-right: 20px;
            margin-bottom: 9px;
            padding-bottom: 6px;

            &.selected {

                color: var( --color-primary );
                border-color: var( --color-primary );
                font-weight: 700;

            }

        }

    }

}
