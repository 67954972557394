.page-push {
    align-items: center;
    width: 100%;
    padding: 30px;
    display: flex;
    flex-direction: column;

    @media (max-width: 576px) {
        padding: 15px;
    }

    .main-div {
        display: flex;
        flex-direction: column;
        justify-content: left;
        position: relative;
        width: 1252px;

        @media (max-width: 576px) {
            width: 100%;
        }
    }

    .toolbar {
        display: flex;

        justify-content: right !important;
        margin-bottom: 0 !important;
        margin-top: 24px !important;

        .button {
            margin-left: 15px;
            height: 100%;
            margin-bottom: 24px;
        }
    }

    .card-div {
        padding: 26px 20px 0px 20px;
        flex-direction: column;
        align-items: flex-end;
    }

    .button-div {
        display: flex;
        padding-top: 20px;
        padding-bottom: 8px;

        flex-direction: column;
        align-items: flex-end;
        width: 100%;

        .button {
            min-width: 203px;
        }
    }

    .page-container-div {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 16px;

        .title-div {
            display: flex;
            align-items: center;
            margin-bottom: 16px;
        }

        .content-div {
            display: flex;

            .content-action-div {
                display: flex;
                flex-direction: row;
                width: 100%;

                &.column-direction {
                    flex-direction: column;
                }

                .content-action-id-div {
                    width: 30%;
                    margin-right: 8px;
                }
            }
        }

        .inner-container {
            display: flex;
            flex-direction: row;

            .phone-notification-container {
                display: flex;
                flex-direction: column;
                margin-left: 60px;

                width: 372px;
                gap: 10px;
            }
        }

        .progress-div {
            display: flex;
            width: 40px;
            flex-direction: column;
            align-items: center;
            margin-right: 36px;
            min-height: 22px;

            &.zero-height {
                min-height: 0px;
            }

            .progress-number {
                margin: 0;
                height: 40px;
                width: 40px;
                border-radius: 20px;
                border: 0px;
                background: #979797;
                align-items: center;
                display: flex;
                justify-content: center;
                color: var(--color-white);
                font-family: var(--font-primary);
                font-size: 22px;
                font-weight: 700;
                line-height: 26px;

                &.active {
                    background: var(--color-blue);
                }
            }

            .progress-line {
                height: 100%;
                width: 2px;
                background: var(--color-blue);
            }
        }
    }

    .title-text-bottom-padding {
        padding-bottom: 15px;
    }

    .title-text-top-padding {
        padding-top: 15px;
    }

    .text-section-container {
        width: 100% !important;
    }

    .push-message-table {
        margin: 0;
        margin-top: 18px;
        padding: 0;
    }

    .react-datepicker-wrapper {
        margin-top: 15px;

        .react-datepicker__input-container {
            height: 48px;

            input {
                cursor: pointer;
                border-radius: 8px;
                border: 1px solid var(--color-border);
                height: calc(48px - 14px - 14px) !important;
                min-width: 210px;
                padding: 14px 16px;
                text-align: center;
                font-size: 14px;
                font-weight: 400;
                line-height: 16px;
                color: var(--color-font);
                font-family: var(--font-primary);
            }
        }
    }

    .check-box-group {
        padding-top: 0;
    }

    .hidden {
        display: none;
    }
}
